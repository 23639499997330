import axios from "axios";
import { server } from "./index";
import axiosInstance from "./axiosInstance";

// create prompt
export const getAllPrompts = () =>
  axiosInstance.get(`/prompt/get-all`, { headers: { "Content-Type": "multipart/form-data" } })

// gets active
export async function getActivePrompt() {
  try {
    const response = await axios.get(`${server}/prompt/active`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// get specific
export async function getPostPromptById(promptId) {
  try {
    const response = await axios.get(`${server}/prompt/${promptId}`, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

// creates new prompt
export async function createPrompt(data, headers) {
  try {
    const response = await axios.post(`${server}/prompt/create`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updatePrompt(promptId, data) {
  console.log(data);
  try {
    const response = await axios.patch(
      `${server}/prompt/${promptId}/update-active`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
