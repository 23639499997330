import React from "react";
import {
  Typography,
  Button,
  Grid,
} from "@mui/material";

import { createClientName } from "../api/client";
import { useForm, FormProvider } from "react-hook-form";
import TextFieldControlled from "../components/TextFieldControlled";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function NewClientName(props) {
  const form = useForm()
  const { watch, setError, setValue } = form
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const onSubmit = async data => {
    try {
      const response = await createClientName(data);
      enqueueSnackbar('Client Name Added!')
      navigate('/all-clients')
    } catch (error) {
      if (error.response) {
        const errors = error.response.data;
        if (errors.email) {
          setError('email', {
            type: "server",
            message: errors.email,
          });
        }
      }
    }
  }

  return (
    <div className="new-client-form-container">
      <Typography variant="h6" style={{ marginBottom: "20px" }}>
        New Client Name
      </Typography>
      <FormProvider {...form}>
        <form className="new-client-name" onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldControlled name="name" label="Client Name" fullWidth required/>
            </Grid>
            <Grid item xs={12}>
              <TextFieldControlled name="email" label="Email" fullWidth required/>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Button variant="contained" fullWidth type="submit">
                Create Client
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
}

export default NewClientName;
