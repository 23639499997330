import axios from "axios";
import { server } from "./index";

// get all keys
export async function getAllKeys() {
  try {
    const response = await axios.get(`${server}/openaikey/get-all`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// gets active
export async function getActiveKey() {
  try {
    const response = await axios.get(`${server}/openaikey/active`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// get specifc key
export async function getKeyById(promptId) {
  try {
    const response = await axios.get(`${server}/openaikey/${promptId}`, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

// update key
export async function updateKey(promptId, data) {
  console.log(data);
  try {
    const response = await axios.patch(
      `${server}/openaikey/${promptId}/update-status`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// create new key
export async function createOpenAiKey(data, headers) {
  try {
    const response = await axios.post(`${server}/openaikey/create`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}
