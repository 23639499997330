import axios from "axios";
import { server } from "./index";

// Param: email, password
export async function loginUser(data, headers) {
  try {
    const response = await axios.post(`${server}/auth/login`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

// Param: email, firstName, lastName, password, company name
export async function registerUser(data, headers) {
  try {
    const response = await axios.post(`${server}/auth/create`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

// Param: body
export async function updateUser(data, headers) {
  try {
    const response = await axios.patch(`${server}/auth/${data.id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}
