import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Snackbar,
  SnackbarContent,
  FormControl,
  InputLabel,
} from "@mui/material";

// api
import { createCompany, getClientsNames } from "../api/client";
import useFetch from "../hooks/useFetch";

function NewClientCompany(props) {
  const [company, setCompany] = useState({
    IdealCustomer: "",
    Problem: "",
    Solution: "",
    ProductService: "",
    clientName: "",
    Website: "",
    LinkedIn: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const clientNames = useFetch(getClientsNames, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await createCompany(company);
      console.log(response.data); // or do something else with the response
      //   reset state
      setCompany({
        IdealCustomer: "",
        Problem: "",
        Solution: "",
        ProductService: "",
        clientName: "",
        Website: "",
        LinkedIn: "",
      });
      setSnackbarOpen(true); // show snackbar when company is created
    } catch (error) {
      console.error(error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="new-client-form-container">
      <Typography
        variant="h6"
        style={{ marginBottom: "20px" }}
        onClick={() => {
          console.log(clientNames);
        }}
      >
        New Company
      </Typography>
      <form className="new-company" onSubmit={handleSubmit}>
        <TextField
          label="Ideal Customer"
          variant="outlined"
          value={company.IdealCustomer}
          onChange={(event) =>
            setCompany({ ...company, IdealCustomer: event.target.value })
          }
          required
          fullWidth
        />
        <TextField
          label="Problem"
          variant="outlined"
          value={company.Problem}
          onChange={(event) =>
            setCompany({ ...company, Problem: event.target.value })
          }
          required
          fullWidth
        />
        <TextField
          label="Solution"
          variant="outlined"
          value={company.Solution}
          onChange={(event) =>
            setCompany({ ...company, Solution: event.target.value })
          }
          required
          fullWidth
        />
        <TextField
          label="Product/Service"
          variant="outlined"
          value={company.ProductService}
          onChange={(event) =>
            setCompany({ ...company, ProductService: event.target.value })
          }
          required
          fullWidth
        />
        <FormControl variant="outlined" fullWidth required>
          <InputLabel id="client-name-label">Client Name</InputLabel>
          <Select
            labelId="client-name-label"
            label="Client Name"
            value={company.clientName}
            onChange={(event) =>
              setCompany({ ...company, clientName: event.target.value })
            }
            required
            fullWidth
          >
            {[...clientNames].reverse().map(
              (
                client // Create a copy of the array and then reverse it
              ) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <TextField
          label="Website"
          variant="outlined"
          value={company.Website}
          onChange={(event) =>
            setCompany({ ...company, Website: event.target.value })
          }
          required
          fullWidth
        />
        <TextField
          label="LinkedIn"
          variant="outlined"
          value={company.LinkedIn}
          onChange={(event) =>
            setCompany({ ...company, LinkedIn: event.target.value })
          }
          required
          fullWidth
        />
        <Button variant="contained" fullWidth type="submit">
          Create Company
        </Button>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SnackbarContent message="Company Added!" />
      </Snackbar>
    </div>
  );
}

export default NewClientCompany;
