import { Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { createPrompt } from "../api/superAdmin";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

function NewPostPrompt(props) {
  const [prompt, setPrompt] = useState("");
  // eslint-disable-next-line
  const [status, setStatus] = useState("inactive");
  const navigate = useNavigate();

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleSubmit = () => {
    if (prompt.trim() === "") {
      return; // Return early if the prompt field is empty or contains only whitespace
    }

    const newPrompt = {
      prompt,
      status,
    };

    createPrompt(newPrompt)
      .then((response) => {
        console.log(response); // Handle success response
        navigate(`/post-prompts`);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error); // Handle error response
      });
  };

  return (
    <>
      <IconButton onClick={handleGoBack}>
        <ArrowBackIcon />
      </IconButton>
      <div className="create-post-prompt-container">
        <div className="create-post-prompt">
          <Typography variant="h5">Create New Prompt for Posts</Typography>
          <Resizable
            height={200} // Initial height of the resizable component
            width={400} // Initial width of the resizable component
            minConstraints={[200, 200]} // Minimum height and width of the resizable component
            maxConstraints={[800, 800]} // Maximum height and width of the resizable component
          >
            <TextField
              label="Prompt"
              value={prompt}
              onChange={handlePromptChange}
              fullWidth
              multiline
              minRows={10} // Minimum number of rows to display
              maxRows={50} // Maximum number of rows to display
              margin="normal"
            />
          </Resizable>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={!prompt.trim()} // Disable the button if the prompt field is empty or contains only whitespace
          >
            Create Prompt
          </Button>
        </div>
      </div>
    </>
  );
}

export default NewPostPrompt;
