
import { useEffect, useState } from "react";

function useStateInitialAsync(func) {
  const [state, setState] = useState()

  useEffect(() => {
    func().then(response => setState(response.data))
  }, [])

  return [state, setState]
}

export default useStateInitialAsync
