import axios from "axios";
import { server } from "./index";
import axiosInstance from "./axiosInstance";

export const getClientNames = () => axiosInstance.get(`/client/ayrshareEnabledClients`)

export const saveClientOnboarding = (data) => axiosInstance.post(`/client/client-onboarding`, data)

export const updateClientName = (client) =>
  axiosInstance.patch(`/client/client-names/${client._id}`, client)

export const uploadFile = (file) => {
  const form = new FormData();
  form.append('avatar', file);

  return axiosInstance.post('/post/upload-file', form)
}

// sample get urls
export const getClientsNames = () =>
  axiosInstance.get(`/client/clientNames`, undefined, { "Content-Type": "multipart/form-data" })

// get companies
export const getCompanies = () => axiosInstance.get(`/client/company`)

// sample get urls
export async function getClientsCompanies(id, callback) {
  await axios
    .get(`${server}/client/company/client-name/${id}`, callback, {
      "Content-Type": "multipart/form-data",
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

// save pecsrr post
export async function savePECSRRPost(data, callback) {
  await axios
    .post(`${server}/query/pecsrr-create`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

// get all pecsrr posts
export const getAllPECSRRQueries = () =>
  axiosInstance.get(`/query/pecsrr-all`, { "Content-Type": "multipart/form-data" })

export async function getPECSRRQueryById(id, callback) {
  await axios
    .get(`${server}/query/pecsrr-post/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

// create new client name
export const createClientName = (data) => axios.post(`${server}/client/create-clientnames`, data)

// create client company
export async function createCompany(data, headers) {
  try {
    const response = await axios.post(`${server}/client/create-company`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

// get posts for specifc client name
export async function getPECSRRQueriesByClientName(clientName, callback) {
  await axios
    .get(`${server}/query/pecsrr-queries/client-name/${clientName}`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

// get posts for specifc client company
export async function getPECSRRQueriesByClientCompany(clientCompany, callback) {
  await axios
    .get(`${server}/query/pecsrr-queries/client-company/${clientCompany}`, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

export const getClientById = (id) => axiosInstance.get(`/client?id=${id}`)

export const getCompaniesByClient = async (id) =>
  axiosInstance.get(`/client/company/client-name/${id}`)

export const getLoggedInClient = () => axiosInstance.get('/client/logged-in')
