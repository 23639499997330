import "./App.css"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import React, { Suspense } from "react"
import LandingPage from "./pages/LandingPage"
import LoginPage from "./pages/LoginPage"
import AllPecsRR from "./pages/AllPecsRR"
import PecsrrPostDetail from "./pages/PecsrrPostDetail"
import NewClientname from "./pages/NewClientname"
import NewClientCompany from "./pages/NewClientCompany"
import AllClients from "./pages/AllClients"
import AllCompanies from "./pages/AllCompanies"
import NewUser from "./pages/NewUser"
import ClientNamePosts from "./pages/ClientNamePosts"
import PostPrompts from "./pages/PostPrompts"
import PostPromptDetails from "./pages/PostPromptDetails"
import NewPostPrompt from "./pages/NewPostPrompt"
import OpenAIKey from "./pages/OpenAIKey"
import NewOpenAIKey from "./pages/NewOpenAiKey"
import Header from "./components/layouts/header"
import Sidebar from "./components/layouts/Sidebar"
import { checkUserAuth, getUserDataSession } from "./helpers/userAuthHelper"
import SettingsPage from "./pages/SettingsPage"
import Prompts from "./pages/Prompts"
import { Container, Stack } from "@mui/material"
import ClientTonePrompt from "./pages/ClientTonePrompt"
import { UserContext } from "./context/UserContext"
import { ClientCalendar } from "./pages/ClientCalendar.jsx"
import { NotificationsPage } from "./pages/NotificationsPage"
import ClientOnboarding from "./pages/ClientOnboarding"
import Playground from "./pages/Playground"
import Feedback from "./pages/Feedback";
import TOTOArticles from "./pages/TOTOArticles"
import PickTOTOArticles from "./pages/PickTOTOArticles"
import TotoClientPicks from "./pages/TotoClientPicks"

const Client = React.lazy(() => import("./pages/Client"))
const Post = React.lazy(() => import("./pages/Post"))
const PostList = React.lazy(() => import("./pages/PostList"))
const PecsRRPage = React.lazy(() => import("./pages/PecsRRPage"))

export const ROUTE = {
  POST: "/post",
  NOTIFICATIONS: "/notifications",
}

export const toPost = id => `${ROUTE.POST}?id=${id}`

function App() {
  const [user, setUser] = React.useState()
  const isAuth = checkUserAuth()
  const location = useLocation()

  if (isAuth && !user) {
    setUser(getUserDataSession())
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {isAuth ? (
        <Stack direction="row">
          {location.pathname !== "/login" && <Sidebar />}
          <div className={location.pathname === "/login" ? "" : "main-content"}>
            <Header />
            <Routes>
              <Route exact path="/landing" element={<LandingPage />} />
              <Route
                exact
                path="/"
                element={
                  isAuth ? (
                    user?.role === "client" ? (
                        <Navigate to="/notifications" replace />
                    ) : (
                      <Navigate to="/pecsrr" replace />
                    )
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                exact
                path="/pecsrr"
                element={
                  isAuth ? (
                    <Suspense fallback={<p>Loading...</p>}>
                      <PecsRRPage />
                    </Suspense>
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route exact path="/all-pecsrr" element={<AllPecsRR />} />
              <Route exact path="/login" element={<LoginPage />} />
              <Route exact path="/new-client-name" element={<NewClientname />} />
              <Route
                exact
                path="/client"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <Container maxWidth="sm">
                      <Client />
                    </Container>
                  </Suspense>
                }
              />
              <Route
                path="/client/tone-prompt"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <Container maxWidth="sm">
                      <ClientTonePrompt />
                    </Container>
                  </Suspense>
                }
              />
              <Route
                exact
                path="/all-clients"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <Container maxWidth="md">
                      <AllClients />
                    </Container>
                  </Suspense>
                }
              />
              <Route exact path="/all-companies" element={<AllCompanies />} />
              <Route exact path="/settings" element={<SettingsPage />} />
              <Route exact path="/new-user" element={<NewUser />} />
              <Route exact path="/new-client-company" element={<NewClientCompany />} />
              <Route
                exact
                path="/client-name/posts/:clientId/:clientName"
                element={<ClientNamePosts />}
              />
              <Route exact path="/all-pecsrr/:postId" element={<PecsrrPostDetail />} />
              <Route exact path="/prompts" element={<Prompts />} />
              <Route exact path="/post-prompts" element={<PostPrompts />} />
              <Route exact path="/prompts/:promptId" element={<PostPromptDetails />} />
              <Route exact path="/new-post-prompt" element={<NewPostPrompt />} />
              <Route exact path="/openai-key" element={<OpenAIKey />} />
              <Route exact path="/new-openai-key" element={<NewOpenAIKey />} />
              <Route
                path="post"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <Post />
                  </Suspense>
                }
              />
              <Route
                path="posts"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <PostList />
                  </Suspense>
                }
              />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="calendar" element={<ClientCalendar />} />
              {
                user?.role === "client" && (
                  <Route path="onboarding" element={
                    <Container maxWidth="sm">
                    <ClientOnboarding />
                    </Container>
                    } />
                )
              }
              <Route path="sandbox" element={<Container maxWidth="md">
                <Suspense fallback={<p>Loading...</p>}>
                <Playground />
                </Suspense>
                </Container>}
              />
              <Route path="feedback" element={<Container maxWidth="sm">
                  <Feedback/>
              </Container>}
              />
              <Route path="toto-articles" element={<TOTOArticles />} />
              <Route path="pick-toto-articles" element={<PickTOTOArticles />} />
              <Route path="toto-client-picks" element={<TotoClientPicks />} />
            </Routes>
          </div>
        </Stack>
      ) : (
        <LoginPage />
      )}
    </UserContext.Provider>
  )
}

export default App
