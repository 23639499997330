import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { getPickedArticles } from "../api/api"
import React from "react"
import useFetch from "../hooks/useFetch"

export default function () {
  const picks = useFetch(getPickedArticles)

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        TOTO Article Client Picks
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Post Date</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Publisher</TableCell>
              <TableCell>Article Link</TableCell>
              <TableCell>Article Title</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {picks?.map((row) => (
              <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{row.dateShort}</TableCell>
                <TableCell>{row.client.name}</TableCell>
                <TableCell>{row.article.category}</TableCell>
                <TableCell>{row.article.publisher}</TableCell>
                <TableCell>{row.article.link}</TableCell>
                <TableCell>{row.article.title}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
