import TextFieldControlled from "../components/TextFieldControlled"
import {
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import {
  deleteTOTOArticle,
  getAllArticles,
  getAllCategories,
  saveArticle,
  saveCategory,
} from "../api/api"
import SelectControlled from "../components/SelectControlled"
import React, { useEffect, useState } from "react"
import useFetch from "../hooks/useFetch"
import { DatePickerControlled } from "../components/DatePickerControlled"
import { DateTime } from "luxon"
import DeleteOutline from "@mui/icons-material/DeleteOutline"

export default function () {
  const articles = useFetch(getAllArticles)
  const [categories, setCategories] = useState()

  const form = useForm()
  const {
    formState: { isSubmitting },
  } = form
  const { enqueueSnackbar } = useSnackbar()

  const refreshCategories = () => getAllCategories().then(({ data }) => setCategories(data))

  useEffect(() => {
    refreshCategories()
  }, [])

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Publisher</TableCell>
              <TableCell>Article Link</TableCell>
              <TableCell>Article Title</TableCell>
              <TableCell>Date Published</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles?.map((row) => (
              <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell>{row.publisher}</TableCell>
                <TableCell>{row.link}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>
                  {DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_SHORT)}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      if (window.confirm("Do you really want to delete this article?")) {
                        deleteTOTOArticle(row.id)
                        window.location.reload()
                      }
                    }}
                    color="error"
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Container maxWidth="sm">
        <FormProvider {...form}>
          <Stack
            component="form"
            onSubmit={form.handleSubmit(async (data) => {
              await saveArticle(data)
              enqueueSnackbar("Article saved!")
              window.location.reload()
            })}
            spacing={1.5}
          >
            <SelectControlled
              name="category"
              fullWidth
              items={categories?.map(({ name }) => ({ value: name, label: name }))}
              label="Category"
              required
            />
            <TextFieldControlled name="publisher" label="Publisher" required />
            <TextFieldControlled name="link" label="Article Link" required />
            <TextFieldControlled name="title" label="Article Title" required />
            <TextFieldControlled name="content" label="Article Content" multiline required />
            <DatePickerControlled name="date" label="Publish Date" required fullWidth />
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Add Article
            </Button>
          </Stack>
        </FormProvider>
        <br />
        <br />
        <CategoryForm onSave={refreshCategories} />
      </Container>
    </>
  )
}

const CategoryForm = ({ onSave }) => {
  const form = useForm()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(async (data) => {
          await saveCategory(data)
          enqueueSnackbar("Category saved!")
          form.reset()
          onSave()
        })}
        spacing={1.5}
      >
        <TextFieldControlled name="name" label="Category" required />
        <Button type="submit" variant="contained">
          Add Category
        </Button>
      </Stack>
    </FormProvider>
  )
}
