import { NotificationsList } from "../components/NotificationsList"
import { Stack, Typography } from "@mui/material"
import { useNotifications } from "../hooks/useNotifications"

export const NotificationsPage = () => {
  const notifications = useNotifications()

  return (
    <Stack alignItems="center">
      <Typography variant="h6">Action Required</Typography>
      <NotificationsList maxWidth={480} hideSubheader notifications={notifications} />
    </Stack>
  )
}
