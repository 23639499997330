import React, { useEffect, useState } from "react";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";

import { getAllPECSRRQueries } from "../api/client";

import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";

function AllPecsRR(props) {
  const posts = useFetch(getAllPECSRRQueries);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  if (!posts) {
    return <Typography variant="caption">Loading...</Typography>;
  }

  const rows = posts.length
    ? posts.map((query) => ({
        id: query._id,
        title: query.title,
        clientName: query.clientName.name,
        clientCompany: query.clientCompany.ProductService,
        tags: query.tags.join(", "),
        // user: `${query.user.first_name || ""} ${query.user.last_name || ""}`,
        createdAt: new Date(query.createdAt).toLocaleDateString(),
        promptProblem: query.promptProblem,
        promptCost: query.promptCost,
        promptSolution: query.promptSolution,
      }))
    : [];

  // Filter rows based on search query
  //   const filteredRows = rows.filter(
  //     (row) =>
  //       Object.values(row).some((value) =>
  //         value.toLowerCase().includes(searchQuery.toLowerCase())
  //       ) ||
  //       row.tags
  //         .split(", ")
  //         .some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
  //   );

  //   exlude title
  const filteredRows = rows.filter(
    (row) =>
      Object.values(row)
        // .filter((value, index) => index !== 1) // Exclude title field
        .some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery.toLowerCase())
        ) ||
      row.tags
        .split(", ")
        .some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleViewDetails = (postId) => {
    // pass the post id then useEffect the suer id to get the details
    navigate(`/all-pecsrr/${postId}`);
    console.log(postId);
  };

  return (
    <div className="all-pecsrr-cotainer">
      <Typography
        variant="h6"
        style={{ marginBottom: "20px" }}
        onClick={() => {
          console.log(posts);
        }}
      >
        PECSRR Posts ({filteredRows.length})
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <div className="pecsr-list">
        <TableContainer component={Paper}>
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Client Company</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    style={{
                      maxWidth: 400,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.title.length > 40
                      ? row.title.slice(0, 50) + "..."
                      : row.title}
                  </TableCell>

                  <TableCell>{row.clientName}</TableCell>
                  <TableCell>{row.clientCompany}</TableCell>
                  <TableCell>{row.tags}</TableCell>
                  <TableCell>{row.user}</TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleViewDetails(row.id);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell> P&C&S</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Tags</TableCell>
                {/* <TableCell>User</TableCell> */}
                <TableCell>Created At</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    style={{
                      maxWidth: 400,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.title.length > 40
                      ? row.title.slice(0, 50) + "..."
                      : row.title}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 300,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`[P] ${row.promptProblem}`} <br />
                    {`[C] ${row.promptCost}`}
                    <br />
                    {`[S] ${row.promptSolution}`}
                  </TableCell>

                  <TableCell>{row.clientName}</TableCell>
                  <TableCell>{row.tags}</TableCell>
                  {/* <TableCell>{row.user}</TableCell> */}
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleViewDetails(row.id);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AllPecsRR;
