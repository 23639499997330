/**
 * Description of the function
 * @template T
 * @callback InitialAsyncFunction
 * @function
 * @returns {Promise<axios.AxiosResponse<T>>} AxiosPromise
 */

import { useEffect, useState } from "react";

/**
 * @template T
 * @param {InitialAsyncFunction<T>} func func
 * @returns {T} state
 */
function useFetchDeps(func, deps, initialState = undefined) {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    func(deps).then(response => setState(response.data))
  }, deps)

  return state
}

export default useFetchDeps
