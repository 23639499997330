import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getActivePrompt, getAllPrompts } from "../api/superAdmin";
import useFetch from "../hooks/useFetch";

function PostPrompts(props) {
  const [activePrompt, setActivePrompt] = useState(null);
  const allPrompts = useFetch(getAllPrompts);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the active prompt
    getActivePrompt()
      .then((response) => {
        if (response) {
          setActivePrompt(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleViewPrompt = (promptId) => {
    navigate(`/prompts/${promptId}`);
  };

  const handleNewPrompt = () => {
    navigate(`/new-post-prompt`);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return allPrompts ? (
    <div>
      <Button variant="contained" size="large" onClick={handleNewPrompt}>
        New Post Prompt
      </Button>
      <div
        style={{
          marginTop: "20px",
          border: "1px solid black",
          padding: "20px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => handleViewPrompt(activePrompt._id)}
      >
        <Typography variant="h5">Active Prompt: </Typography>
        {/* Render active prompt */}
        {activePrompt && (
          <div>
            <Typography style={{ whiteSpace: "pre-line" }}>
              {activePrompt && activePrompt.prompt}
            </Typography>
          </div>
        )}
      </div>

      <div style={{ marginTop: "50px" }}>
        <Typography variant="h5">All Prompts</Typography>
        {/* Render all prompts in a table */}
        {allPrompts.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prompt</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPrompts.map((prompt) => (
                  <TableRow
                    key={prompt._id}
                    style={{
                      borderLeft:
                        prompt._id === activePrompt?._id
                          ? "4px solid green"
                          : "none",
                    }}
                  >
                    <TableCell>{truncateString(prompt.prompt, 100)}</TableCell>
                    <TableCell>
                      {new Date(prompt.createdAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </TableCell>
                    {/* Replace with appropriate property name for created timestamp */}
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleViewPrompt(prompt._id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  ) : null
}

export default PostPrompts;
