import { Stack, Typography } from "@mui/material"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { DateTime } from "luxon"

export const PostPreview = ({ post, datetimeFormat }) => (
  <Stack sx={{ maxHeight: "3.5rem", overflow: "hidden" }}>
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row">
        <LinkedInIcon fontSize="small" />
        <Typography variant="body2" ml={0.5} color="inherit">
          {post.clientName.name}
        </Typography>
      </Stack>
      <Typography overflow="hidden" variant="caption">
        {DateTime.fromISO(post.publishTime).toLocaleString(datetimeFormat)}
      </Typography>
    </Stack>
    <Typography textAlign="left" variant="caption" sx={{ textOverflow: "ellipsis" }}>
      {post.title}
    </Typography>
  </Stack>
)
