import { createContext } from "react"

/**
 * @typedef TUserContext
 * @property {User} user
 // * @property {'finished' | 'inprogress'} status
 // * @property {Array} notificationActionsts
 */

/** @type {import('react').Context<TUserContext>} */
export const UserContext = createContext()
