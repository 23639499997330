import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { useEffect } from "react"
import { getClientNames } from "../api/client"
import useStateInitialAsync from "../hooks/useStateInitialAsync"
import PropTypes from "prop-types"
import { Controller, useFormContext } from "react-hook-form"

function ClientSelect(props) {
  const {
    required = false,
    name,
    fullWidth = false,
    noDefaultSelected = false,
    size = "medium",
    minWidth,
    ...rest
  } = props
  const form = useFormContext()
  const { control, setValue, getValues } = form
  const [clientNames] = useStateInitialAsync(getClientNames)

  useEffect(() => {
    !getValues(name) && !noDefaultSelected && setValue(name, clientNames?.[0]?._id)
  }, [clientNames])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = "" } }) => (
        <FormControl
          required={required}
          fullWidth={fullWidth}
          size={size}
          sx={{ ...(minWidth && { minWidth }) }}
        >
          <InputLabel id="client-select-label">Client</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            labelId="client-select-label"
            label="Select Client"
            {...rest}
          >
            {clientNames?.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            )) ?? []}
          </Select>
        </FormControl>
      )}
    />
  )
}

ClientSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default ClientSelect
