import React, { useEffect } from "react";
import { getAllPrompts } from "../api/superAdmin";

function LandingPage(props) {
  useEffect(() => {
    // Fetch all prompts
    getAllPrompts()
      .then((response) => {
        console.log(response[0].prompt); // Log the response
      })
      .catch((error) => {
        console.log(error); // Log any error
      });
  }, []);

  return (
    <div>
      <h1>Landing Page</h1>
    </div>
  );
}

export default LandingPage;
