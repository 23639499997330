import React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { Controller, useFormContext } from "react-hook-form"

export default function ({ options, legend, name }) {
  const form = useFormContext()

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{legend}</FormLabel>
      <Controller
        control={form.control}
        name={name}
        render={({ field: { value = null, onChange } }) => (
          <RadioGroup value={value} onChange={onChange} name={name}>
            {options.map(({ label, value }) => (
              <FormControlLabel
                value={value}
                control={<Radio size="small" required />}
                label={label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
