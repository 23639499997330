import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TextField,
  TablePagination,
  Stack,
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import { getClientsNames } from "../api/client";
import useFetch from "../hooks/useFetch";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { reprocessComments } from "../api/api"

function AllClients(props) {
  const clientNames = useFetch(getClientsNames, [])
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const handleViewQueries = (clientId, clientName) => {
    navigate(`/client-name/posts/${clientId}/${clientName}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredClients = clientNames.filter((client) =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredClients.length - page * rowsPerPage);

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        All Clout Selling Clients
      </Typography>
      <TextField
        label="Search clients"
        variant="outlined"
        margin="dense"
        style={{ marginBottom: "20px" }}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
      />
      <TableContainer component={Paper}>
        <Table style={{ marginBottom: "50px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Tone of Voice Prompt</TableCell>
              <TableCell>LinkedIn Account Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredClients.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredClients
            ).map((client) => (
              <TableRow key={client._id}>
                <TableCell>
                  <Link to={`/client?id=${client._id}`}>{client.name}</Link>
                </TableCell>
                <TableCell>{client.tonePrompt ? "✓" : ""}</TableCell>
                <TableCell>
                  {client.linkedInAccountLinked === false ? (
                    <CancelIcon color="error" />
                  ) : (
                    <CheckCircleIcon color="success" />
                  )}
                </TableCell>
                <TableCell>
                  <Stack spacing={1} direction="row">
                    {client.linkedInAccountLinked === false && (
                      <Button
                        variant="outlined"
                        onClick={async () => {
                          await reprocessComments(client._id)
                          window.location.reload(false)
                        }}
                      >
                        Re-process comments
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => handleViewQueries(client._id, client.name)}
                    >
                      View Queries
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

export default AllClients;
