import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { getCompanies } from "../api/client";
import useFetch from "../hooks/useFetch";

function AllCompanies(props) {
  const companies = useFetch(getCompanies)

  return companies ? (
    <Box display="flex" justifyContent="center" margin="auto" marginTop="50px">
      <TableContainer style={{ marginBottom: "50px" }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ideal Customer</TableCell>
              <TableCell>Problem</TableCell>
              <TableCell>Solution</TableCell>
              <TableCell>Product/Service</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>LinkedIn</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company._id}>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.IdealCustomer}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.Problem}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.Solution}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.ProductService}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.clientName.name}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.Website}
                </TableCell>
                <TableCell
                  style={{
                    maxWidth: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {company.LinkedIn.replace(/^(https?:\/\/)?(www\.)?/, "")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : null;
}

export default AllCompanies;
