import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { LocalizationProvider } from "@mui/x-date-pickers"
import theme from "./theme"
import { ThemeProvider } from "@mui/material"
import { SnackbarProvider } from "notistack"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { routerHistory } from "./history"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { Settings } from 'luxon';
import ModalProvider from "mui-modal-provider"

Settings.defaultZone = 'America/New_York';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HistoryRouter history={routerHistory}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
          <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <ModalProvider>
              <App />
            </ModalProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </HistoryRouter>
  </React.StrictMode>,
)
