const AccessControl = require("accesscontrol")

export const RESOURCE = Object.freeze({
  NOTIFICATIONS: "NOTIFICATIONS",
  ONBOARDING: "ONBOARDING",
  CALENDAR: "CALENDAR",
  CREATE_PECSRR: "CREATE_PECSRR",
  ALL_PECSRR: "ALL_PECSRR",
  SCHEDULE_LI_POST: "SCHEDULE_LI_POST",
  ALL_LI_POSTS: "ALL_LI_POSTS",
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_COMPANY: "CREATE_COMPANY",
  CREATE_USER: "CREATE_USER",
  ALL_CLIENTS: "ALL_CLIENTS",
  ALL_COMPANIES: "ALL_COMPANIES",
  SETTINGS: "SETTINGS",
  PROMPTS: "PROMPTS",
  POST_PROMPTS: "POST_PROMPTS",
  OPENAI_KEY: "OPENAI_KEY",
  PLAYGROUND: "PLAYGROUND",
  TOTO_ARTICLES: "TOTO_ARTICLES",
  PICK_TOTO_ARTICLES: "PICK_TOTO_ARTICLES",
  TOTO_CLIENT_PICKS: "TOTO_CLIENT_PICKS",
})

export const ac = new AccessControl()

ac.grant("va")
  .readAny(RESOURCE.CALENDAR)
  .readAny(RESOURCE.CREATE_PECSRR)
  .readAny(RESOURCE.ALL_PECSRR)
  .readAny(RESOURCE.SCHEDULE_LI_POST)
  .readAny(RESOURCE.ALL_LI_POSTS)
  .readAny(RESOURCE.ALL_CLIENTS)

  .grant("admin")
  .extend("va")
  .readAny(RESOURCE.CREATE_CLIENT)
  .readAny(RESOURCE.CREATE_COMPANY)
  .readAny(RESOURCE.CREATE_USER)
  .readAny(RESOURCE.ALL_COMPANIES)
  .readAny(RESOURCE.SETTINGS)
  .readAny(RESOURCE.TOTO_ARTICLES)
  .readAny(RESOURCE.TOTO_CLIENT_PICKS)

  .grant("superadmin") // define new or modify existing role. also takes an array.
  .extend("admin")
  .readAny(RESOURCE.PROMPTS)
  .readAny(RESOURCE.POST_PROMPTS)
  .readAny(RESOURCE.OPENAI_KEY)
  .readAny(RESOURCE.PLAYGROUND)

ac.grant("client")
  .readAny(RESOURCE.NOTIFICATIONS)
  .readAny(RESOURCE.ONBOARDING)
  .readAny(RESOURCE.CALENDAR)
  .readAny(RESOURCE.SCHEDULE_LI_POST)
  .readAny(RESOURCE.ALL_LI_POSTS)
  .readAny(RESOURCE.PICK_TOTO_ARTICLES)
