import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";

function SelectControlled(props) {
  const id = useId()
  const { required = false, name, label, items, sx, fullWidth = false, size } = props
  const form = useFormContext()
  const { control } = form

  return (
    <Controller
      name={name} control={control} render={({ field: { onChange, value = '' } }) =>
      <FormControl required={required} fullWidth={fullWidth} sx={sx} {...(size && { size })}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          labelId={id}
          label={label}
        >
          {items?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )) ?? []}
        </Select>
      </FormControl>
    }
    />
  )
}

export default SelectControlled
