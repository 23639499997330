import { getSASUploadUrl } from "../api/api"
import { convertFileToArrayBuffer } from "../lib/convert-file-to-arraybuffer"
import { BlockBlobClient } from "@azure/storage-blob"

export const handleFileChange = async (e) => {
  /** @type {File} openedFile */
  const openedFile = e.target.files?.[0]
  if (!openedFile) return

  const {
    data: { sasUrl, blobName, file },
  } = await getSASUploadUrl(openedFile.name)
  if (sasUrl === "") return

  await convertFileToArrayBuffer(openedFile).then((fileArrayBuffer) => {
    if (
      fileArrayBuffer === null ||
      fileArrayBuffer.byteLength < 1 ||
      fileArrayBuffer.byteLength > 256000
    )
      return

    const blockBlobClient = new BlockBlobClient(sasUrl)
    return blockBlobClient.uploadData(fileArrayBuffer)
  })

  return file
}
