import React, { useEffect, useState } from "react"
import {
  Typography,
  Button,
  Stack,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material"
import { useModal } from "mui-modal-provider"
import { getClientsNames } from "../api/client"
import useFetch from "../hooks/useFetch"
import TextFieldControlled from "../components/TextFieldControlled"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionActions from "@mui/material/AccordionActions"
import IconButton from "@mui/material/IconButton"
import HistoryIcon from "@mui/icons-material/History"
import { DateTime } from "luxon"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { getRevisions, getSandbox, saveRevision, saveSandbox } from "../api/api"
import { useSnackbar } from "notistack"
import {
  generateHeadlines,
  getCompletion,
  getCompletionStreamed,
  getToneOfVoiceOutput,
} from "../ChatGPTClient"
import dataFetch from "../WrapPromise"
import { PROMPT } from "./Prompts"
import useStateInitialAsync from "../hooks/useStateInitialAsync"

const resource = dataFetch()

function Playground(props) {
  const form = useForm({ defaultValues: () => getSandbox().then((res) => res.data) })
  const clientNames = useFetch(getClientsNames, [])
  const { showModal } = useModal()
  const prompts = resource.prompts.read()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data) => {
    await saveSandbox(data)
    enqueueSnackbar("Saved!")
  }

  const onSaveRevision = async (type) => {
    await saveRevision({ type, content: form.getValues(type) })
    enqueueSnackbar("Saved!")
  }

  return (
    <>
      <Typography
        variant="h6"
        style={{ marginBottom: "20px" }}
        onClick={() => {
          console.log(clientNames)
        }}
      >
        Playground
      </Typography>
      <FormProvider {...form}>
        <Stack component="form" spacing={3} onSubmit={form.handleSubmit(onSubmit)}>
          <TextFieldControlled
            name="IdealCustomer"
            label="Ideal Customer"
            variant="outlined"
            required
            fullWidth
          />
          <TextFieldControlled
            name="Problem"
            label="Problem"
            variant="outlined"
            required
            fullWidth
          />
          <TextFieldControlled
            name="CostOfInaction"
            label="Cost of Inaction"
            variant="outlined"
            required
            fullWidth
          />
          <TextFieldControlled
            name="Solution"
            label="Solution"
            variant="outlined"
            required
            fullWidth
          />
          <TextFieldControlled
            name="writing"
            label="Sample writing"
            variant="outlined"
            fullWidth
            multiline
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Client Tone of Voice Prompt
            </AccordionSummary>
            <AccordionDetails>
              <TextFieldControlled
                fullWidth
                name="toneOfVoicePrompt"
                label="Client Tone of Voice Prompt"
                id="outlined-start-adornment"
                multiline
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          showModal(PromptVersionHistoryModal, {
                            setRevision: (v) => form.setValue("toneOfVoicePrompt", v),
                            type: "toneOfVoicePrompt",
                          })
                        }
                        edge="end"
                      >
                        <HistoryIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </AccordionDetails>
            <AccordionActions>
              <Button onClick={() => onSaveRevision("toneOfVoicePrompt")}>Save revision</Button>
            </AccordionActions>
          </Accordion>
          <Button
            onClick={async () => {
              const interpolatedToneOfVoicePrompt = form
                .getValues("toneOfVoicePrompt")
                .replace("${clientWriting}", form.getValues("writing"))
              const clientToneOfVoice = await getToneOfVoiceOutput(interpolatedToneOfVoicePrompt)
              form.setValue("clientToneOfVoice", clientToneOfVoice)
            }}
            type="submit"
          >
            Generate client tone of voice
          </Button>
          <TextFieldControlled
            name="clientToneOfVoice"
            label="Client Tone of Voice"
            variant="outlined"
            fullWidth
            multiline
          />
          <div>
            <PromptAccordion
              summary="PECSRR Post Prompt"
              name="pecsrrPostPrompt"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
            <PromptAccordion
              summary="PECSRR Post With Tone of Voice Prompt"
              name="pecsrrPostWithTonePrompt"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
            <PromptAccordion
              summary="PECSRR Titles"
              name="pecsrrTitles"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
            <PromptAccordion
              summary="PECSRR Comments"
              name="pecsrrComments"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
            <PromptAccordion
              summary="PECSRR Replies"
              name="pecsrrReplies"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
            <PromptAccordion
              summary="PECSRR Hashtags"
              name="pecsrrHashtags"
              showModal={showModal}
              onSaveRevision={onSaveRevision}
            />
          </div>
          <Stack direction="row" spacing={1.5} justifyContent="flex-end">
            <Button type="submit">Save</Button>
            <Button
              variant="contained"
              onClick={() => showModal(OutputModal, { prompts, ...form.getValues() })}
            >
              Generate
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  )
}

const PromptAccordion = ({ summary, name, showModal, onSaveRevision }) => {
  const form = useFormContext()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        <TextFieldControlled
          fullWidth
          name={name}
          label={summary}
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    showModal(PromptVersionHistoryModal, {
                      setRevision: (v) => form.setValue(name, v),
                      type: name,
                    })
                  }
                  edge="end"
                >
                  <HistoryIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={() => onSaveRevision(name)}>Save revision</Button>
      </AccordionActions>
    </Accordion>
  )
}

export default Playground

// Create the dialog you want to use
const PromptVersionHistoryModal = ({ type, setRevision, ...props }) => {
  const [revisions] = useStateInitialAsync(() => getRevisions(type))

  return (
    <Dialog {...props}>
      <DialogTitle>
        {type === "pecsrrPostWithTonePrompt"
          ? "PECSRR Post With Tone of Voice Prompt History"
          : type === "pecsrrPostPrompt"
          ? "PECSRR Post Prompt History"
          : "Client Tone of Voice Prompt History"}
      </DialogTitle>
      <DialogContent>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          {revisions?.map(({ content, timestamp }) => (
            <ListItem
              key={timestamp}
              disableGutters
              secondaryAction={
                <IconButton
                  aria-label="comment"
                  onClick={() => {
                    setRevision(content)
                    props.onClose()
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_FULL)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

const OutputModal = ({
  clientToneOfVoice,
  pecsrrPostWithTonePrompt,
  pecsrrTitles,
  pecsrrComments,
  pecsrrReplies,
  pecsrrHashtags,
  pecsrrPostPrompt,
  IdealCustomer,
  Problem,
  CostOfInaction,
  writing,
  Solution,
  ...props
}) => {
  const [post, setPost] = useState("")
  const [comments, setComments] = useState("")
  const [titles, setTitles] = useState("")
  const [replies, setReplies] = useState("")
  const [hashtags, setHashtags] = useState("")
  const [interpolPostPrompt, setInterpolPostPrompt] = useState("")
  const [interpolHashtagsPrompt, setInterpolHashtagsPrompt] = useState("")
  const [interpolTitlesPrompt, setInterpolTitlesPrompt] = useState("")
  const [interpolCommentsPrompt, setInterpolCommentsPrompt] = useState("")
  const [interpolRepliesPrompt, setInterpolRepliesPrompt] = useState("")

  useEffect(() => {
    const prompt = pecsrrPostWithTonePrompt
      .replace("${clientToneOfVoice}", clientToneOfVoice)
      .replace("${problem}", Problem)
      .replace("${cost}", CostOfInaction)
      .replace("${solution}", Solution)
      .replace("${targetAudience}", IdealCustomer)
    setInterpolPostPrompt(prompt)
    getCompletionStreamed(prompt).then(setPost)
  }, [])

  useEffect(() => {
    if (!post) return
    const numberOfComments = 15
    const interpolatedCommentsPrompt = pecsrrComments
      .replace("${numberOfComments}", numberOfComments)
      .replace("${post}", post)
    setInterpolCommentsPrompt(interpolatedCommentsPrompt)
    getCompletionStreamed(interpolatedCommentsPrompt).then((text) =>
      setComments(
        text
          .split("|")
          .map((c, index) => `${index + 1}. ${c.trim()}`)
          .slice(0, numberOfComments)
          .join("\n\n"),
      ),
    )

    const prompt = pecsrrTitles.replace("${pecsRR}", post)
    setInterpolTitlesPrompt(prompt)
    generateHeadlines(prompt, post).then(setTitles)
  }, [post])

  useEffect(() => {
    if (!titles) return
    const prompt = pecsrrHashtags
      .replace("${targetAud}", IdealCustomer)
      .replace("${pecsRRTitles}", titles)
    setInterpolHashtagsPrompt(prompt)
    getCompletion(prompt).then(setHashtags)
  }, [titles])

  useEffect(() => {
    if (comments) {
      const prompt = pecsrrReplies.replace("${pecsRRComments}", comments)
      setInterpolRepliesPrompt(prompt)
      getCompletion(prompt).then(setReplies)
    }
  }, [comments])

  return (
    <Dialog {...props} maxWidth="md">
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1.5}>
            {[
              { value: post, label: "Post" },
              { value: comments, label: "Comments" },
              { value: titles, label: "Titles" },
              { value: replies, label: "Replies" },
              { value: hashtags, label: "Hashtags" },
            ].map(({ value, label }) => (
              <Stack style={{ whiteSpace: "pre-wrap" }}>
                <Typography variant="subtitle2">{label}:</Typography>
                <Typography variant="body2">{value}</Typography>
              </Stack>
            ))}
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="title">Prompts</Typography>
            {[
              { value: interpolPostPrompt, label: "Post" },
              { value: interpolCommentsPrompt, label: "Comments" },
              { value: interpolTitlesPrompt, label: "Titles" },
              { value: interpolRepliesPrompt, label: "Replies" },
              { value: interpolHashtagsPrompt, label: "Hashtags" },
            ].map(({ value, label }) => (
              <Stack style={{ whiteSpace: "pre-wrap" }}>
                <Typography variant="subtitle2">{label}:</Typography>
                <Typography variant="body2">{value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
