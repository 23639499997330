import { Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { createOpenAiKey } from "../api/openAIKeys";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

function NewOpenAIKey(props) {
  const [apiKey, setApiKey] = useState("");
  // eslint-disable-next-line
  const [status, setStatus] = useState("inactive");
  const navigate = useNavigate();

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleSubmit = () => {
    if (apiKey.trim() === "") {
      return; // Return early if the apiKey field is empty or contains only whitespace
    }

    const newKey = {
      apiKey,
      status,
    };

    createOpenAiKey(newKey)
      .then((response) => {
        console.log(response); // Handle success response
        navigate(`/openai-key`);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error); // Handle error response
      });
  };

  return (
    <>
      <IconButton onClick={handleGoBack}>
        <ArrowBackIcon />
      </IconButton>
      <div className="create-open-ai-key-container">
        <div className="create-open-ai-key">
          <Typography variant="h5">Create New OpenAI Key</Typography>
          <TextField
            label="API Key"
            value={apiKey}
            onChange={handleApiKeyChange}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={!apiKey.trim()} // Disable the button if the apiKey field is empty or contains only whitespace
          >
            Create Key
          </Button>
        </div>
      </div>
    </>
  );
}

export default NewOpenAIKey;
