import { DatePicker } from "@mui/x-date-pickers"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

export const DatePickerControlled = (props) => {
  const {
    fullWidth = false,
    name,
    label,
    required = false,
    sx = {},
    maxDate = undefined,
    minDate,
    shouldDisableDate,
  } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = null }, fieldState, formState }) => (
        <DatePicker
          onChange={onChange}
          value={value}
          slotProps={{ textField: { required, fullWidth } }}
          label={label}
          maxDate={maxDate}
          sx={sx}
          minDate={minDate}
          shouldDisableDate={shouldDisableDate}
        />
      )}
    />
  )
}
