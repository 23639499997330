import { Box, Popover } from "@mui/material"
import React, { useState } from "react"

const MouseoverMenu = ({ children, element }) => {
  const [anchorEl, setAnchorEl] = useState()

  const handlePopoverOpen = (event) => {
    setAnchorEl(event?.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box
      onMouseOver={(event) => handlePopoverOpen(event)}
      onMouseOut={handlePopoverClose}
      sx={{ cursor: "pointer" }}
    >
      <Box aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true">
        {element}
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus={true}
        onClick={handlePopoverClose}
        PaperProps={{ sx: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
      >
        <Box sx={{ pointerEvents: "auto" }}>{children}</Box>
      </Popover>
    </Box>
  )
}

export default MouseoverMenu
