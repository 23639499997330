import { Typography, TextField, Button, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { registerUser } from "../api/user";

function NewUser(props) {
  const [user, setUser] = useState({
    email: "",
    password: "",
    first_name: "",
    lastNalast_nameme: "",
    username: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await registerUser(user);
      console.log(response.data); // or do something else with the response
      // reset state and show snackbar
      setUser({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        username: "",
      });
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="create-new-user">
      <Typography variant="h6">New User</Typography>
      <form className="new-user-form" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          value={user.email}
          onChange={(event) => setUser({ ...user, email: event.target.value })}
          required
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={user.password}
          onChange={(event) =>
            setUser({ ...user, password: event.target.value })
          }
          required
        />
        <TextField
          label="First Name"
          variant="outlined"
          value={user.firstName}
          onChange={(event) =>
            setUser({ ...user, first_name: event.target.value })
          }
          required
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={user.lastName}
          onChange={(event) =>
            setUser({ ...user, last_name: event.target.value })
          }
          required
        />
        <TextField
          label="Username"
          variant="outlined"
          value={user.username}
          onChange={(event) =>
            setUser({ ...user, username: event.target.value })
          }
          required
        />
        <Button variant="contained" fullWidth type="submit">
          Create User
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="User Created!"
      />
    </div>
  );
}

export default NewUser;
