import { Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPostPromptById, updatePrompt } from "../api/superAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

function PostPromptDetails() {
  const { promptId } = useParams();
  const [prompt, setPrompt] = useState(null);
  const [editedPrompt, setEditedPrompt] = useState("");
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the prompt by ID
    getPostPromptById(promptId)
      .then((response) => {
        setPrompt(response);
        setEditedPrompt(response.prompt);
        setIsActive(response.status === "active");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [promptId]);

  if (!prompt) {
    return null; // Return null or a loading state while fetching the prompt
  }

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handlePromptChange = (event) => {
    setEditedPrompt(event.target.value);
  };

  const handleToggleActive = () => {
    setIsActive(!isActive);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedPromptData = {
      prompt: editedPrompt,
      status: isActive ? "active" : "inactive",
    };

    updatePrompt(promptId, updatedPromptData)
      .then((response) => {
        console.log(response); // Handle success response
        window.location.reload();
      })
      .catch((error) => {
        console.log(error); // Handle error response
      });
  };

  return (
    <div>
      <IconButton onClick={handleGoBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h5">
        {new Date(prompt.createdAt).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </Typography>
      <form className="update-prompt-field" onSubmit={handleSubmit}>
        <Button
          variant="contained"
          onClick={handleToggleActive}
          style={{ margin: "20px 0px" }}
          mt={2}
          color={isActive ? "secondary" : "primary"}
        >
          {isActive ? "Deactivate" : "Set Active"}
        </Button>
        <TextField
          label="Prompt"
          value={editedPrompt}
          onChange={handlePromptChange}
          multiline
          fullWidth
        />

        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          type="submit"
          mt={2}
        >
          Save
        </Button>
      </form>
      {/* Render other details of the prompt as needed */}
    </div>
  );
}

export default PostPromptDetails;
