import { useForm, FormProvider } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import React from "react"
import { getClientById, updateClientName } from "../api/client"
import { Button, Stack, Typography } from "@mui/material"
import TextFieldControlled from "../components/TextFieldControlled"
import { generateClientWritingStylePrompt } from "../ChatGPTClient"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useSnackbar } from "notistack"

export default function () {
  const form = useForm({
    defaultValues: async () => getClientById(clientId).then((response) => response.data),
  })
  const { getValues, setValue, watch } = form
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const clientId = params.get("clientId")
  const navigate = useNavigate()
  const clientName = watch("name")
  const { enqueueSnackbar } = useSnackbar()

  const goBackToClientDetail = () => {
    navigate({
      pathname: "/client",
      search: new URLSearchParams({ id: clientId }).toString(),
    })
  }

  return (
    <FormProvider {...form}>
      <Button sx={{ px: 0 }} onClick={() => goBackToClientDetail()} startIcon={<ArrowBackIcon />}>
        back to client detail
      </Button>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(async ({ writing, tonePrompt }) => {
          await updateClientName({ writing, tonePrompt, _id: clientId })
          enqueueSnackbar("Tone of voice prompt updated!")
          goBackToClientDetail()
        })}
        spacing={4}
        mt={2}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Tone of voice prompt - {clientName}</Typography>
          <Typography variant="body2">
            Gather your most you-like content. Identify three pieces of written content that reflect
            your true voice or the voice you want to train ChatGPT on.
          </Typography>
          <TextFieldControlled fullWidth name="writing" label="Content" multiline />
          <Button
            onClick={async () => {
              const tonePrompt = await generateClientWritingStylePrompt(getValues("writing"))
              setValue("tonePrompt", tonePrompt)
            }}
          >
            Create prompt
          </Button>
        </Stack>
        <Stack spacing={1}>
          <TextFieldControlled fullWidth name="tonePrompt" label="Tone Prompt" multiline />
        </Stack>
        <Button type="submit" variant="contained">
          Save
        </Button>
        {/*<Typography variant="body2">*/}
        {/*  Or, if your content lives online and you have access to ChatGPT Plus, you can paste the*/}
        {/*  URL to all your articles and use this prompt:*/}
        {/*</Typography>*/}
      </Stack>
    </FormProvider>
  )
}
