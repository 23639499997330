
import { useEffect, useState } from "react";

function useFetch(func, initialState) {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    func().then(response => setState(response.data))
  }, [])

  return state
}

export default useFetch
