import { createMuiTheme, createTheme } from "@mui/material"

export const lightTokens = {
  palette: {
    primary: {
      // main: '#212C44',
      main: '#13426B',
    },
    secondary: { main: "#F16334" },
  }
}

const theme = createMuiTheme(lightTokens)

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
})

export default theme
