import React, { useEffect } from "react";
import {
  Typography,
  Tabs, Tab, Grid, Button, TextField, Container,
} from "@mui/material";

// api
import Box from "@mui/material/Box";
import { useForm, FormProvider } from "react-hook-form";
import { getSettings, saveSettings } from "../api/api";
import { useSnackbar } from "notistack";
import TextFieldControlled from "../components/TextFieldControlled";
import SelectControlled from "../components/SelectControlled"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return value === index && children
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const defaultValues = { minInterval: '', maxInterval: '', ayrshareApiKey: '' }

function SettingsPage(props) {
  const form = useForm({ defaultValues })
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const { data: settings } = await getSettings()
      form.reset(settings)
    })()
  }, [])

  const onSubmit = async (data) => {
    const { data: saved } = await saveSettings(data)
    enqueueSnackbar("Settings saved!")
    form.reset(saved)
  }

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center">
        <Typography variant="h6" sx={{ marginBottom: "12px" }}>Settings</Typography>
      </Box>
      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
          sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Ayrshare" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Grid container rowSpacing={4} p={4}>
            <CustomTabPanel value={value} index={0}>
              <Grid container item xs={12} spacing={3}>
                <Grid container item xs={12} columnSpacing={1.5}>
                  <Grid item xs={12}>
                    <Typography pb={0.5}>Interval between comments (in min):</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldControlled
                      fullWidth
                      required
                      name="minInterval"
                      label="Min"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldControlled
                      fullWidth
                      required
                      name="maxInterval"
                      label="Max"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldControlled
                    fullWidth
                    required
                    name="replyToCommentsWait"
                    label="Wait before Replying to Comments (in min):"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                <SelectControlled
                  fullWidth
                  name="model"
                  label="ChatGPT Model"
                  items={[
                    { label: "gpt-4-0314", value: "gpt-4-0314" },
                    { label: "gpt-4", value: "gpt-4" },
                    { label: "gpt-4o", value: "gpt-4o" },
                  ]}
                  size="small"
                />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid item xs={12}>
                <TextFieldControlled name="ayrshareApiKey" label="Ayrshare API Key" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth type="submit">
                  Save
                </Button>
              </Grid>
            </CustomTabPanel>
          </Grid>
        </Box>
      </FormProvider>
    </Container>
  )
}

export default SettingsPage;
