import TextFieldControlled from "../components/TextFieldControlled"
import { Button, Stack } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { sendFeedback } from "../api/api"

export default function () {
  const form = useForm()
  const {
    formState: { isSubmitting },
  } = form
  const { enqueueSnackbar } = useSnackbar()

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(async (data) => {
          await sendFeedback(data)
          enqueueSnackbar("Feedback sent!")
          form.reset()
        })}
        spacing={1.5}
      >
        <TextFieldControlled name="feedback" label="Feedback" multiline />
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Send
        </Button>
      </Stack>
    </FormProvider>
  )
}
