import axios from "axios";
import { server } from "./index";
import { routerHistory } from "../history";

const instance = axios.create({ baseURL: server })

export const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const getTokenFromLocalStorage = () => JSON.parse(localStorage.getItem("token"))

export const saveTokenToLocalStorage = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
}

const token = getTokenFromLocalStorage()
if (token) {
  setAuthorizationHeader(token)
}


instance.interceptors.response.use(undefined, error => {
  const statusCode = error.response ? error.response.status : null;

  if (statusCode === 401) {
    localStorage.removeItem("user-data")
    routerHistory.replace("/");
  }

  // if (statusCode >= 500) {
  //   // show server error
  // }
  //
  // if (statusCode === 400) {
  //   // show bad request error
  // }

  return Promise.reject(error);
});

export default instance
