import { Card, ListItemButton, ListSubheader, Stack, Typography } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import { PostPreview } from "./PostPreview"
import List from "@mui/material/List"
import React from "react"
import { DateTime } from "luxon"
import { toPost } from "../App"
import { canPickArticlesNow } from "../helpers/TotoArticleHelper"

export const NotificationsList = (props) => {
  const { maxWidth, hideSubheader = false, notifications } = props

  return (
    <List
      sx={{
        minWidth: maxWidth,
        maxWidth: maxWidth,
        bgcolor: "background.paper",
        width: maxWidth,
      }}
      {...(!hideSubheader && {
        subheader: (
          <ListSubheader component="div" id="nested-list-subheader">
            Notifications
          </ListSubheader>
        ),
      })}
    >
      {canPickArticlesNow && (
          <ListItem alignItems="flex-start" disablePadding>
            <ListItemButton onClick={() => (window.location.href = "/pick-toto-article")}>
              <Stack width="100%">
                <Typography variant="subtitle2">Pick next 12 articles for TOTO Posts</Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        )}
      {notifications?.map((post) => (
        <>
          <ListItem alignItems="flex-start" disablePadding>
            <ListItemButton onClick={() => (window.location.href = toPost(post._id))}>
              <Stack width="100%">
                <Typography variant="subtitle2">
                  {post.postComment
                    ? `${post.postComment.createdBy.first_name} ${post.postComment.createdBy.last_name} commented on:`
                    : `Pending approval:`}
                </Typography>
                <Card variant="outlined" sx={{ p: 1 }}>
                  <PostPreview
                    post={post}
                    beforeToday={true}
                    datetimeFormat={DateTime.DATETIME_MED}
                  />
                </Card>
              </Stack>
            </ListItemButton>
          </ListItem>
          {/*<Divider variant="inset" component="li" />*/}
        </>
      ))}
    </List>
  )
}
