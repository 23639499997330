import { Configuration, OpenAIApi } from "openai"
import { server } from "./api"
import { getSettings, sendChatRequest } from "./api/api"
import { getPrompt } from "./api/PromptService"
import { PROMPT } from "./pages/Prompts"

export const getCompletion = async (prompt) => {
  const {
    data: { model },
  } = await getSettings()
  const configuration = new Configuration({
    basePath: `${server}/v1`,
    organization: "org-SfyUsC5kfHAByNkg7W3z8yGL",
  })
  delete configuration.baseOptions.headers["User-Agent"]
  const openai = new OpenAIApi(configuration)
  try {
    const completion = await openai.createChatCompletion({
      model,
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
    })
    return completion.data.choices[0].message.content
  } catch (error) {
    console.log(error)
  }
}

export const generatePECSRRComments = (prompt, post, numberOfComments = 15) => {
  const commentsPrompt = prompt
    .replace("${numberOfComments}", numberOfComments)
    .replace("${post}", post)
  return getCompletionStreamed(commentsPrompt)
}

export const generateRepliestoComments = async (prompt, comments, numberOfReplies) => {
  const repliesPrompt = prompt
    .replace("${numberOfReplies}", numberOfReplies)
    .replace("${comments}", comments)
  return getCompletion(repliesPrompt)
}

export const generatePECSRRHashtags = async (prompt, title, targetAudience, n) => {
  const hashtagsPrompt = prompt
    .replace("${title}", title)
    .replace("${targetAudience}", targetAudience)
    .replace("${n - 1}", n - 1)
  return getCompletion(hashtagsPrompt)
}

export const getCompletionStreamed = async (message) => {
  const {
    data: { model },
  } = await getSettings()
  const response = await sendChatRequest({
    model,
    messages: [
      {
        role: "user",
        content: message,
      },
    ],
  })

  // ... (Rest of the code for reading and displaying responses)
  const reader = response.body.getReader()
  const textDecoder = new TextDecoder("utf-8")

  // Display the assistant's response in chunks
  let assistantResponse = ""
  while (true) {
    const { value, done } = await reader.read()
    if (done) break

    assistantResponse += textDecoder.decode(value, { stream: !done })
  }
  return assistantResponse
}

export const generateClientWritingStylePrompt = async (clientWriting) => {
  const {
    data: { prompt },
  } = await getPrompt(PROMPT.CLIENT_TONE)
  const tonePrompt = prompt.replace("${clientWriting}", clientWriting)
  return getCompletionStreamed(tonePrompt)
}

export const getToneOfVoiceOutput = (tonePrompt) =>getCompletionStreamed(tonePrompt, "gpt-4")

export const generatePECSRRCommentsNumbered = async (prompt, post, numberOfComments = 15) => {
  const text = await generatePECSRRComments(prompt, post, numberOfComments)
  return text
    .split("|")
    .map((c, index) => `${index + 1}. ${c.trim()}`)
    .slice(0, numberOfComments)
    .join("\n\n")
}

export const generatePostWithTone = async ({
  clientToneOfVoice,
  problem,
  cost,
  solution,
  targetAudience,
}) => {
  const {
    data: { prompt },
  } = await getPrompt(PROMPT.PECSRR_POST_WITH_TONE)
  const interpolatedPrompt = prompt
    .replace("${clientToneOfVoice}", clientToneOfVoice)
    .replace("${problem}", problem)
    .replace("${cost}", cost)
    .replace("${solution}", solution)
    .replace("${targetAudience}", targetAudience)
  return getCompletionStreamed(interpolatedPrompt)
}

export const generateHeadlines = async (titlesPrompt, post) => {
  const prompt = titlesPrompt.replace("${pecsRR}", post)
  const configuration = new Configuration({
    basePath: `${server}/v1`,
    organization: "org-SfyUsC5kfHAByNkg7W3z8yGL",
  })
  delete configuration.baseOptions.headers["User-Agent"]
  const openai = new OpenAIApi(configuration)
  try {
    const completion = await openai.createChatCompletion({
      temperature: 1,
      max_tokens: 1400,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
    })
    return completion.data.choices[0].message.content
  } catch (error) {
    console.log(error)
  }
}