import { Typography } from "@mui/material"
import React from "react"
import { Stack } from "@mui/material"
import { NotificationPopover } from "../NotificationPopover"
import { useLocation } from "react-router-dom"
import { ROUTE } from "../../App"

function Header(props) {
  const location = useLocation()
  const user = JSON.parse(localStorage.getItem("user-data"))
  const first_name = user ? user.first_name : ""

  return (
    <Stack justifyContent="space-between" direction="row" mt={2} mb={4}>
      <Typography variant="h5"> Welcome, {first_name}</Typography>
      {user.role === 'client' && location.pathname !== ROUTE.NOTIFICATIONS && <NotificationPopover />}
    </Stack>
  )
}

export default Header
