import React from "react"
import RadioGroupControlled from "./RadioGroupControlled"

export default function ({ options, legend, name }) {
  return (
    <RadioGroupControlled
      legend={legend}
      name={name}
      options={options.map((option, index)=> ({ value: index + 1, label: option }))}
    />
  )
}
