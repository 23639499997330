import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { useNavigate } from "react-router-dom"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"
import { DateTime, Interval } from "luxon"
import { Box, Button, ButtonBase, Card, IconButton, Stack, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { getAllPosts } from "../api/PostService"
import useFetchDeps from "../hooks/useFetchDeps"
import "./ClientCalendar.css"
import { UserContext } from "../context/UserContext"
import { PostPreview } from "../components/PostPreview"
import { lightGreen, yellow } from "@mui/material/colors"
import { ClientSelect } from "../components"
import { FormProvider, useForm } from "react-hook-form"

export const ClientCalendar = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [date, setDate] = useState(new Date())
  const today = DateTime.fromISO(new Date().toISOString())
  const dt = DateTime.fromISO(date.toISOString())
  const firstDay = dt.startOf("month").startOf("week")
  const lastDay = dt.endOf("month").endOf("week")
  const interval = Interval.fromDateTimes(firstDay, lastDay).splitBy({ days: 1 })
  const form = useForm({ defaultValues: {} })
  const { watch } = form
  const client = watch("clientId")
  const posts = useFetchDeps(
    ([month, client]) => getAllPosts({ month, client }),
    [date, client],
    [],
  )
  const isUserClient = user.role === "client"

  return (
    <>
      <FormProvider {...form}>
        <Stack direction="row" justifyContent="space-between">
          <Box width={256}>
            {!isUserClient && <ClientSelect name="clientId" sx={{ width: 256 }} />}
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
            <IconButton
              onClick={() => {
                const previousMonth = new Date(date)
                previousMonth.setMonth(previousMonth.getMonth() - 1)
                setDate(previousMonth)
              }}
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Typography>{dt.toFormat("LLLL yyyy")}</Typography>
            <IconButton
              onClick={() => {
                const nextMonth = new Date(date)
                nextMonth.setMonth(nextMonth.getMonth() + 1)
                setDate(nextMonth)
              }}
            >
              <ArrowForwardOutlinedIcon />
            </IconButton>
          </Stack>
          <Box width={256}></Box>
        </Stack>
      </FormProvider>
      <Stack direction="row" mt={1} alignContent="stretch">
        {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((weekday) => (
          <Box sx={{ flex: "1 0 14%", textAlign: "center", py: 1, background: "#EFEFEF" }}>
            <Typography variant="caption" color="text.disabled">
              {weekday}
            </Typography>
          </Box>
        ))}
      </Stack>
      <Box display="grid" gridAutoRows="1fr" gridTemplateColumns="repeat(7, 1fr)">
        {interval.map((dt) => {
          const filtered = posts.filter((post) =>
            DateTime.fromISO(post.publishTime).hasSame(dt.start, "day"),
          )
          const beforeToday = dt.start.ts < today.startOf("day").ts
          const isThisDayToday = today.hasSame(dt.start, "day")
          return (
            <>
              {/*<Divider orientation="vertical" flexItem />*/}
              <Stack
                sx={{
                  background: "linear-gradient(#EFEFEF, #EEE)",
                  p: "8px 8px 48px 8px",
                  ...(beforeToday && { color: "text.secondary" }),
                  // borderTop: 1,
                  // borderColor: "divider",
                }}
                className="on-hover-parent"
                spacing={1}
                gridColumn="span 1"
              >
                <Box
                  alignSelf="flex-end"
                  sx={{
                    ...(isThisDayToday && {
                      backgroundColor: "error.main",
                      color: "#fff",
                      borderRadius: "50%",
                      width: 24,
                    }),
                  }}
                >
                  <Typography textAlign="center" {...(beforeToday && { color: "text.secondary" })}>
                    {dt.start.toFormat(dt.start.day === 1 && !isThisDayToday ? "MMM d" : "d")}
                  </Typography>
                </Box>
                <Box sx={{ height: 40 }}>
                  {!beforeToday && (
                    <Button
                      sx={{ borderStyle: "dashed" }}
                      className="on-hover-child"
                      variant="outlined"
                      fullWidth
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={() =>
                        navigate("/post", { state: { publishTimeISO: dt.start.toISO() } })
                      }
                    >
                      New Post
                    </Button>
                  )}
                </Box>
                {filtered.map((post) => (
                  <ButtonBase onClick={() => navigate(`/post?id=${post._id}`)}>
                    <Card
                      sx={{
                        ...(beforeToday && { color: "text.secondary" }),
                        p: 1,
                        width: "100%",
                        ...(!post.approved && { backgroundColor: yellow["A100"] }),
                        ...(post.approved &&
                          post.scheduledByVA && { backgroundColor: lightGreen["A100"] }),
                      }}
                      {...(beforeToday && { variant: "outlined" })}
                    >
                      <PostPreview post={post} datetimeFormat={DateTime.TIME_SIMPLE} />
                    </Card>
                  </ButtonBase>
                ))}
              </Stack>
            </>
          )
        })}
      </Box>
    </>
  )
}
