import { TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

function TextFieldControlled(props) {
  const {
    disabled = false,
    required = false,
    type,
    fullWidth,
    name,
    label,
    multiline,
    size = "medium",
    variant = "outlined",
    inputProps,
    minRows = 8,
    sx,
    ...rest
  } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value = "" },
        fieldState: { error },
        formState: { errors },
      }) => (
        <TextField
          error={error}
          helperText={error?.message ?? ""}
          disabled={disabled}
          size={size}
          onChange={(event) => {
            onChange(event)
            props.changed?.(event)
          }}
          value={value}
          {...(type && { type })}
          {...(fullWidth && { fullWidth })}
          label={label}
          {...(multiline && { multiline, minRows })}
          variant={variant}
          required={required}
          {...(inputProps && { inputProps })}
          {...(sx && { sx })}
          {...rest}
        />
      )}
    />
  )
}

export default TextFieldControlled
