import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getAllKeys, getActiveKey, updateKey } from "../api/openAIKeys";
import { useNavigate } from "react-router-dom";

function OpenAIKey(props) {
  const [openAiKeys, setOpenAiKeys] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const navigate = useNavigate();

  const handleNewKey = () => {
    navigate(`/new-openai-key`);
  };

  useEffect(() => {
    // Fetch all OpenAI keys
    getAllKeys()
      .then((response) => {
        if (response) {
          setOpenAiKeys(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch the active OpenAI key
    getActiveKey()
      .then((response) => {
        if (response) {
          setActiveKey(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSetActiveKey = (keyId) => {
    const confirmAction = window.confirm(
      "Please make sure this is an active key! Do you want to continue?"
    );

    if (confirmAction) {
      updateKey(keyId, { status: "active" })
        .then((response) => {
          if (response) {
            setActiveKey(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <div className="active-key-container">
        <Button variant="contained" onClick={handleNewKey}>
          Create New Key
        </Button>
        <Typography style={{ marginTop: "20px" }} variant="h5">
          OpenAI Key
        </Typography>
      </div>

      <div className="active-key-container">
        <Typography variant="subtitle1">Active Key:</Typography>
        {activeKey ? (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>API Key:</TableCell>
                  <TableCell>{activeKey.apiKey}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status:</TableCell>
                  <TableCell>{activeKey.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created At:</TableCell>
                  <TableCell>
                    {new Date(activeKey.createdAt).toLocaleDateString("en-US")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No active key found.</Typography>
        )}
      </div>
      <div className="list-of-keys">
        <Typography variant="subtitle1">All Keys:</Typography>
        {openAiKeys.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>API Key</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {openAiKeys.map((key) => (
                  <TableRow
                    key={key._id}
                    style={{
                      borderLeft:
                        key.status === "active" ? "4px solid green" : "none",
                    }}
                  >
                    <TableCell>{key.apiKey}</TableCell>
                    <TableCell>{key.status}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleSetActiveKey(key._id)}
                      >
                        Set Active
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No keys found.</Typography>
        )}
      </div>
    </div>
  );
}

export default OpenAIKey;
