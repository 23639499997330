import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getPECSRRQueriesByClientName } from "../api/client";
import { useNavigate } from "react-router-dom";

function ClientNamePosts(props) {
  const [clientQueries, setClientQueries] = useState([]);
  const { clientName, clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPECSRRQueriesByClientName(clientId, (response) => {
      if (response.status === 200) {
        console.log(response.data);
        setClientQueries(response.data);
      } else {
        console.log("Error getting queries:", response.data.message);
      }
    });
  }, [clientId]);

  const handleViewDetails = (postId) => {
    navigate(`/all-pecsrr/${postId}`);
    console.log(postId);
  };

  return (
    <div>
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        PECSRR Queries for: {clientName}
      </Typography>
      {clientQueries.length === 0 ? (
        <Typography>No posts yet.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table style={{ marginBottom: "50px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell> P&C&S</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientQueries.map((query) => (
                <TableRow key={query._id}>
                  <TableCell
                    style={{
                      maxWidth: 400,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {query.title.length > 40
                      ? query.title.slice(0, 50) + "..."
                      : query.title}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 300,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`[P] ${query.promptProblem}`} <br />
                    {`[C] ${query.promptCost}`}
                    <br />
                    {`[S] ${query.promptSolution}`}
                  </TableCell>
                  <TableCell>{query.clientName.name}</TableCell>
                  <TableCell>{query.tags.join(", ")}</TableCell>
                  <TableCell>{query.createdAt}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleViewDetails(query._id)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default ClientNamePosts;
