import { Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPECSRRQueryById } from "../api/client";
import { Snackbar, SnackbarContent } from "@mui/material";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import wordTemplate from "./../constatns/pecsrrpost-template.docx";

function PecsrrPostDetail(props) {
  const { postId } = useParams();
  const [details, setDetails] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // if details dont send a requsest again
    if (details) {
      getPECSRRQueryById(postId, (response) => {
        if (response.status === 200) {
          setDetails(response.data);
          console.log(response.data);
        } else {
          setDetails(response.data.message);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleReturnClick = () => {
    navigate(-1);
  };

  if (details.length <= 0) {
    return <Typography>Loading...</Typography>;
  }

  // handles snackbar close
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  // handles file download
  const generateDocx = () => {
    JSZipUtils.getBinaryContent(wordTemplate, function (error, content) {
      if (error) {
        throw error;
      }

      var zip = new PizZip(content);
      var doc = new Docxtemplater().loadZip(zip);

      //set the templateVariables
      doc.setData({
        title: details.title,
        tags: details.tags.join(", "),
        clientName: details.clientName.name,
        company: details.clientCompany.ProductService,
        requestedBy: `${details.user?.first_name} ${details.user?.last_name}`,
        problem: details.promptProblem,
        cost: details.promptCost,
        solution: details.promptSolution,
        generatedContent: details.generatedContent,
        comments: details.comments,
        replies: details.replies,
        hashtags: details.hashtags,
      });

      try {
        // render the document (replace all occurrences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        var e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties,
        };
        console.log(JSON.stringify({ error: e }));
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
        throw error;
      }

      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Output the document using Data-URI
      saveAs(
        out,
        `${details.clientName.name}${details.clientCompany.ProductService}.docx`
      );
    });
  };

  return (
    <>
      <div className="saved-button">
        {" "}
        <IconButton onClick={() => handleReturnClick()}>
          <ArrowBackIcon />
        </IconButton>
        <Button variant="outlined" onClick={() => generateDocx()}>
          Download .DOCX
        </Button>
      </div>

      <div className="central-post">
        <div className="pecsrr-details">
          {/* div for titles */}
          <div className="pecsrr-detail-titles">
            <Typography style={{ marginBottom: "20px" }} variant="h6">
              Generated Titles for this post:{" "}
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: "pre-wrap" }}>
              {details.title}
            </Typography>
            <div className="post-details-list">
              <div className="pecsrr-detail-tags">
                <Typography variant="body2">
                  <b>Tags:</b> {details.tags.join(", ")}
                </Typography>
              </div>
              <Typography variant="body2">
                <b>Client:</b> {details.clientName.name}
              </Typography>
              <Typography variant="body2">
                <b>Company:</b> {details.clientCompany.ProductService}
              </Typography>
              <Typography variant="body2">
                <b>Requested By:</b> {details.user?.first_name}{" "}
                {details.user?.last_name}
              </Typography>
            </div>
            <div className="post-details-list">
              <Typography variant="body2">
                <b>Problem:</b> {details.promptProblem}
              </Typography>
              <Typography variant="body2">
                <b>Cost of Inaction:</b> {details.promptCost}
              </Typography>
              <Typography variant="body2">
                <b>Solution:</b> {details.promptSolution}
              </Typography>
            </div>
          </div>
          {/* div for contents */}
          <div className="pecsrr-detail-contents">
            <Typography style={{ marginBottom: "20px" }} variant="h6">
              Generated Content for this post:{" "}
            </Typography>
            <Typography variant="subtitle2" style={{ whiteSpace: "pre-wrap" }}>
              {details.generatedContent}
            </Typography>
            <Button
              variant="caption"
              style={{ marginTop: "20px" }}
              sx={{
                mt: 2,
                bgcolor: "#F16334",
                color: "white",
                "&:hover": {
                  bgcolor: "#F16334",
                },
              }}
              onClick={() => {
                setShowSnackbar(true);
                navigator.clipboard.writeText(details.generatedContent);
              }}
            >
              Copy to Clipboard
            </Button>
          </div>
          {/* for comments */}
          {details?.comments ? (
            <>
              <div className="pecsrr-detail-contents">
                <Typography style={{ marginBottom: "20px" }} variant="h6">
                  Generated Comments for this post:{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {details.comments}
                </Typography>
                <Button
                  variant="caption"
                  style={{ marginTop: "20px" }}
                  sx={{
                    mt: 2,
                    bgcolor: "#F16334",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#F16334",
                    },
                  }}
                  onClick={() => {
                    setShowSnackbar(true);
                    navigator.clipboard.writeText(details.comments);
                  }}
                >
                  Copy Comments to Clipboard
                </Button>
              </div>
            </>
          ) : (
            " "
          )}
          {details?.replies ? (
            <>
              <div className="pecsrr-detail-contents">
                <Typography style={{ marginBottom: "20px" }} variant="h6">
                  Generated Replies for this post:{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {details.replies}
                </Typography>
                <Button
                  variant="caption"
                  style={{ marginTop: "20px" }}
                  sx={{
                    mt: 2,
                    bgcolor: "#F16334",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#F16334",
                    },
                  }}
                  onClick={() => {
                    setShowSnackbar(true);
                    navigator.clipboard.writeText(details.comments);
                  }}
                >
                  Copy Replies to Clipboard
                </Button>
              </div>
            </>
          ) : (
            " "
          )}

          {/* for hashtags */}
          {details?.hashtags ? (
            <>
              {" "}
              <div className="pecsrr-detail-contents">
                <Typography style={{ marginBottom: "20px" }} variant="h6">
                  Generated Hashtags for this post:{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {details.hashtags}
                </Typography>
                <Button
                  variant="caption"
                  style={{ marginTop: "20px" }}
                  sx={{
                    mt: 2,
                    bgcolor: "#F16334",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#F16334",
                    },
                  }}
                  onClick={() => {
                    setShowSnackbar(true);
                    navigator.clipboard.writeText(details.hashtags);
                  }}
                >
                  Copy Hashtags to Clipboard
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SnackbarContent message="Post copied to clipboard" />
      </Snackbar>
    </>
  );
}

export default PecsrrPostDetail;
