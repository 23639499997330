import axiosInstance, { getTokenFromLocalStorage } from "./axiosInstance"
import { server } from "./index"

export const getSettings = () => axiosInstance.get(`/settings`)

export const saveSettings = (settings) => axiosInstance.post(`/settings`, settings)

export const sendChatRequest = (request) =>
  fetch(`${server}/chat`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(request),
  })

export const getSASUploadUrl = (filename, pub = false) =>
  axiosInstance.get(`/file/sas-url/ul`, { params: { filename, type: "ul", pub } })

export const getSASDownloadUrl = (id) => axiosInstance.get(`/file/sas-url/dl`, { params: { id } })

export const saveComment = (comment) => axiosInstance.post(`/comment`, comment)

export const resolveComment = (id) =>
  axiosInstance.post(`/comment/resolve`, null, { params: { id } })

export const getNotifications = () => axiosInstance.get("/notifications")

export const getAllVAs = () => axiosInstance.get("/user/va")

export const reprocessComments = (clientId) =>
  axiosInstance.post(`/client/reprocess-comments/${clientId}`)

export const getSandbox = () => axiosInstance.get(`/sandbox`)

export const saveSandbox = (sandbox) => axiosInstance.post(`/sandbox`, sandbox)

export const saveRevision = (revision) => axiosInstance.post(`/sandbox/revision`, revision)

export const getRevisions = (type) => axiosInstance.get(`/sandbox/revision?type=${type}`)

export const sendFeedback = (feedback) => axiosInstance.post(`/feedback`, feedback)

export const saveArticle = (article) => axiosInstance.post(`/toto-article`, article)

export const saveCategory = (category) => axiosInstance.post(`/toto-article-category`, category)

export const getAllArticles = () => axiosInstance.get(`/toto-article`)

export const getAllCategories = () => axiosInstance.get(`/toto-article-category`)

export const deleteTOTOArticle = (id) => axiosInstance.delete(`/toto-article`, { params: { id } })

export const getPickedArticles = () => axiosInstance.get(`/pick-toto-article`)

export const getAvailableArticles = (search, category) =>
  axiosInstance.get(`/toto-article`, { params: { available: 1, search, category } })

export const saveClientArticlePick = (body) => axiosInstance.post(`/pick-toto-article`, body)

export const deletePickedArticle = (id) =>
  axiosInstance.delete(`/pick-toto-article`, { params: { id } })
