import { getClientNames, getClientsNames } from "./api/client"
import { getAllPrompts } from "./api/PromptService"
import { getAllVAs } from "./api/api"

const dataFetch = () => ({
  clients: wrapFunc(getClientNames),
  clientNames: wrapFunc(getClientsNames),
  prompts: wrapFunc(getAllPrompts),
  getAllVAs: wrapFunc(getAllVAs),
})

const wrapFunc = (func) => {
  let status = "pending"
  let result
  let suspend = func().then(
    (res) => {
      status = "success"
      result = res.data
    },
    (err) => {
      status = "error"
      result = err
    },
  )
  return {
    read() {
      if (status === "pending") {
        throw suspend
      } else if (status === "error") {
        throw result
      } else if (status === "success") {
        return result
      }
    },
  }
}

export default dataFetch
