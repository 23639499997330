import { Badge, Card, IconButton, Popover } from "@mui/material"
import { bindPopover, bindToggle, usePopupState } from "material-ui-popup-state/hooks"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import { NotificationsList } from "./NotificationsList"
import React from "react"
import { useNotifications } from "../hooks/useNotifications"

export const NotificationPopover = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoPopper" })
  const notifications = useNotifications()

  return (
    <div>
      <IconButton {...bindToggle(popupState)}>
        {notifications?.length > 0 ? (
          <Badge variant="dot" badgeContent={4} color="primary">
            <NotificationsActiveOutlinedIcon />
          </Badge>
        ) : (
          <NotificationsActiveOutlinedIcon />
        )}
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card elevation={4}>
          <NotificationsList maxWidth={360} notifications={notifications} />
        </Card>
      </Popover>
    </div>
  )
}
