import React, { useContext, useState } from "react"
import clout_logo from "./../images/clout-selling-name-logo.png"

// mui
import TextField from "@mui/material/TextField";
import { Button, FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"

// nav
import { useNavigate } from "react-router-dom";

// api
import { loginUser } from "../api/user";
import {
  getTokenFromLocalStorage,
  saveTokenToLocalStorage,
  setAuthorizationHeader
} from "../api/axiosInstance";
import { UserContext } from "../context/UserContext"
import IconButton from "@mui/material/IconButton"
import { Visibility, VisibilityOff } from "@mui/icons-material"

const initialState = {
  email: "",
  password: "",
  errorMessage: "",
  showPassword: false,
  notEmail: false,
};

function LoginPage(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handles changes
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value.trim(),
    });
  };

  const handleLoginCallback = async (inputValues) => {
    // set loading to true
    // setLoading((prevState) => (prevState = true));
    const data = {
      email: inputValues.email,
      password: inputValues.password,
    };

    // content type has to be json
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await loginUser(data, headers);
      const userData = response.data;
      const userInfo = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        token: userData.token,
        id: userData.id,
        role: userData.role,
      }
      saveTokenToLocalStorage(response.data.token)
      setAuthorizationHeader(getTokenFromLocalStorage())
      localStorage.setItem("user-data", JSON.stringify(userInfo))
      setUser(userData)
      navigate("/")
    } catch (error) {
      // wrong password
      console.log("Wrong");
      setValues((prevState) => ({
        ...prevState,
        errorMessage: "*Email or Password is Incorrect",
        password: "",
        isManualAuthenticated: false,
      }));
    } finally {
      // setLoading((prevState) => (prevState = false));
    }
  };

  return (
    <div className="login-container">
      <div className="login-image-container">
        <img src={clout_logo} alt="clout selling logo" className="text-logo" />
      </div>
      <div className="login-form-container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const inputValues = {
              email: values.email,
              password: values.password,
            };
            handleLoginCallback(inputValues);
            // console.log(inputValues);
          }}
          className="form-container"
        >
          <h1>Login</h1>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            style={{ marginTop: "50px" }}
            fullWidth
            onChange={handleChange("email")}
            value={values.email || ""}
          />
          <FormControl style={{ marginTop: "20px" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="password"
            variant="outlined"
            fullWidth
            error={values.errorMessage !== ""}
            onChange={handleChange("password")}
            value={values.password || ""}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          </FormControl>
          <Button
            variant="contained"
            style={{ marginTop: "20px", backgroundColor: "#F16334" }}
            fullWidth
            type="submit"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
