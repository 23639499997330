import useStateInitialAsync from "./useStateInitialAsync"
import { getNotifications } from "../api/api"
import { useEffect } from "react"

export const useNotifications = () => {
  const [notifications, setNotifications] = useStateInitialAsync(getNotifications)

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications().then((response) => setNotifications(response.data))
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return notifications
}
