import { Button, IconButton, Stack, Typography } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import useFetch from "../hooks/useFetch"
import {
  deletePickedArticle,
  getAllCategories,
  getAvailableArticles,
  getPickedArticles,
  saveClientArticlePick,
} from "../api/api"
import { DataGrid } from "@mui/x-data-grid"
import { DatePickerControlled } from "../components/DatePickerControlled"
import SelectControlled from "../components/SelectControlled"
import TextFieldControlled from "../components/TextFieldControlled"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { getLoggedInClient } from "../api/client"

export default function () {
  const currentPostStartDate = DateTime.now().plus({ quarter: 1 }).startOf("quarter")
  const currentPostEndDate = DateTime.now().plus({ quarter: 1 }).endOf("quarter")
  const picks = useFetch(getPickedArticles)
  const categories = useFetch(getAllCategories)
  const [articles, setArticles] = useState()
  const form = useForm()
  const {
    watch,
    formState: { isSubmitting },
  } = form
  const { enqueueSnackbar } = useSnackbar()
  const category = watch("category")
  const search = watch("search")
  const date = watch("date")
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])

  const fetchArticles = () =>
    getAvailableArticles(search, category).then(({ data }) => setArticles(data))

  useEffect(() => {
    fetchArticles()
  }, [category, date])

  useEffect(() => {
    ;(async () => {
      const { data: client } = await getLoggedInClient()
      form.setValue("category", client?.onboarding?.totoArticleCategory)
    })()
  }, [])

  const pickArticle = async () => {
    try {
      const abc = await saveClientArticlePick({
        article: rowSelectionModel[0],
        date: form.getValues("date"),
      })
      enqueueSnackbar("Article Pick saved!")
      window.location.reload()
    } catch (e) {}
  }

  const handleKeyDown = (e) => {
    const { key, target } = e

    if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
      return
    }

    e.preventDefault()
  }

  return (
    <Stack spacing={6}>
      <DataGrid
        rows={
          picks?.map(({ id, article: { category, publisher, link, title }, dateShort }) => ({
            id,
            category,
            publisher,
            link,
            title,
            dateShort,
          })) ?? []
        }
        columns={[
          { field: "category", headerName: "Category", flex: 1 },
          { field: "publisher", headerName: "Publisher", flex: 1 },
          { field: "link", headerName: "Article Link", flex: 1 },
          { field: "title", headerName: "Article Title", flex: 1 },
          { field: "dateShort", headerName: "Post Date", flex: 1 },
          {
            field: "close",
            headerName: "",
            renderCell: ({ row }) => {
              return (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    if (window.confirm("Do you really want to delete this article?")) {
                      deletePickedArticle(row.id)
                      window.location.reload()
                    }
                  }}
                  color="error"
                >
                  <DeleteOutline />
                </IconButton>
              )
            },
            flex: 1,
          },
        ]}
      />
      <FormProvider {...form}>
        <Stack
          component="form"
          spacing={3}
          onKeyDown={handleKeyDown}
          onSubmit={form.handleSubmit(pickArticle)}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Pick an article for TOTO Post
          </Typography>
          <DatePickerControlled
            name="date"
            label="Post Date"
            required
            sx={{ alignSelf: "flex-start" }}
            maxDate={currentPostEndDate}
            minDate={currentPostStartDate}
            shouldDisableDate={(date) => date.weekday != 5}
          />
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={1.5}>
              <SelectControlled
                size="small"
                name="category"
                sx={{ width: 256 }}
                // fullWidth
                items={categories?.map(({ name }) => ({ value: name, label: name }))}
                label="Category"
              />
              <TextFieldControlled
                name="search"
                size="small"
                label="Search..."
                sx={{ width: 512 }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  fetchArticles()
                }}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  form.reset({ search: "", category: "" }, { keepDefaultValues: true })
                }}
              >
                Clear
              </Button>
            </Stack>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={articles ?? []}
                columns={[
                  { field: "category", headerName: "Category" },
                  { field: "publisher", headerName: "Publisher", minWidth: 128 },
                  { field: "link", headerName: "Article Link", minWidth: 256 },
                  { field: "title", headerName: "Article Title", minWidth: 384 },
                  { field: "dateShort", headerName: "Date Published" },
                ]}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel)
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </Stack>
          <Button
            disabled={rowSelectionModel.length === 0}
            sx={{ alignSelf: "flex-end" }}
            variant="contained"
            type="submit"
            // onClick={onSubmit}
          >
            Pick Article
          </Button>
        </Stack>
      </FormProvider>
    </Stack>
  )
}
