import { usePromiseTracker } from "react-promise-tracker"
import PacmanLoader from "react-spinners/PacmanLoader"
import React from "react"

export const LoadingSpinnerComponent = ({ children, area }) => {
  const { promiseInProgress } = usePromiseTracker({ area })

  return promiseInProgress === true ? (
    <div className="loader-container">
      <PacmanLoader color={"#212C44"} aria-label="Loading Spinner" data-testid="loader" size={25} />
    </div>
  ) : (
    children
  )
}
