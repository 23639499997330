import { useForm, FormProvider } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import React from "react"
import { getLoggedInClient, saveClientOnboarding } from "../api/client"
import { Button, Stack, Typography } from "@mui/material"
import TextFieldControlled from "../components/TextFieldControlled"
import { useSnackbar } from "notistack"
import RadioGroupControlledAuto from "../components/RadioGroupControlledAuto"
import { trackPromise } from "react-promise-tracker"
import { handleFileChange } from "../service/FileUploadService"
import { LoadingSpinnerComponent } from "../components/LoadingSpinnerComponent"
import Chip from "@mui/material/Chip"
import SelectControlled from "../components/SelectControlled"
import useFetch from "../hooks/useFetch"
import { getAllCategories } from "../api/api"

const LoadingArea = { UploadFile: "UploadFile" }

const defaultValues = { files: [] }

export default function () {
  const form = useForm({
    defaultValues: async () => {
      const { data: client } = await getLoggedInClient()
      return client?.onboarding ?? defaultValues
    },
  })
  const { getValues, setValue, watch, formState } = form
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const categories = useFetch(getAllCategories)

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(async (data) => {
          await saveClientOnboarding(data)
          enqueueSnackbar("Onboarding completed!")
          navigate({ pathname: "/" })
        })}
        spacing={3}
        mt={2}
      >
        <Typography variant="h6">Client Onboarding</Typography>
        <TextFieldControlled fullWidth name="fullName" label="Full Name" required />
        <TextFieldControlled fullWidth name="email" label="Email" required />
        <TextFieldControlled fullWidth name="mobile" label="Mobile" required />
        <TextFieldControlled
          fullWidth
          name="linkedinUrl"
          label="LinkedIn URL (e.g.: https://www.linkedin.com/in/mikeweiss/)"
          required
        />
        <TextFieldControlled
          fullWidth
          name="website"
          label="Your biz website URL (it should look like this: https://cloutselling.com/)"
          required
        />
        <Stack spacing={3}>
          <Typography variant="body2">
            We have prepared seven questions for you to answer that will allow us and the AI to
            better understand your writing and communication style. Please choose the best answer
            that applies. (if you are stuck between two answers - the simple writing style answer is
            a good choice)
          </Typography>

          <RadioGroupControlledAuto
            name="Q1"
            legend="1. How do you prefer your sentences to be structured?"
            options={[
              "Short and punchy",
              "Long and flowing",
              "A mix, depending on the mood and pace",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q2"
            legend="2. What level of detail do you prefer in descriptions?"
            options={[
              "Few details and easy to read",
              "Moderate, with some details to paint a picture",
              "Rich with many details",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q3"
            legend="3. How do you want your audience to feel after reading your content?"
            options={[
              "Informed and educated",
              "Moved and inspired",
              "Entertained and happy",
              "Provoked and challenged",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q4"
            legend="4. What role does storytelling play in your writing?"
            options={[
              "Essential – most of my writing is narrative",
              "Important – I use stories to illustrate points",
              "Occasional – I sprinkle in stories for engagement",
              "Minimal – I focus more on facts and information",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q5"
            legend="5. How do you approach controversial or sensitive topics?"
            options={[
              "Directly and boldly, not shying away from tough discussions",
              "Carefully and thoughtfully, considering all sides",
              "Lightly, with a focus on staying neutral or positive",
              "I generally avoid controversial or sensitive topics",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q6"
            legend="6. Which tone best represents your desired writing style?"
            options={[
              "Formal and professional",
              "Conversational and friendly",
              "Inspirational and motivational",
              "Humorous and witty",
            ]}
          />
          <RadioGroupControlledAuto
            name="Q7"
            legend="7. What is the complexity level of the language you prefer?"
            options={[
              "Simple and easy to understand",
              "Moderately complex with some technical jargon",
              "A mix, depending on the context",
            ]}
          />
        </Stack>
        <Stack spacing={1.5}>
          <Typography variant="body2">
            Upload your PDF's. Please upload your book, blogs, or other documents that provide clues
            to your strategies, tactics and methodologies.
          </Typography>
          <LoadingSpinnerComponent area={LoadingArea.UploadFile}>
            <Button
              size="medium"
              sx={{
                height: 40,
                whiteSpace: "nowrap",
                minWidth: "max-content",
                alignSelf: "flex-start",
              }}
              component="label"
              variant="outlined"
              // disabled={isPublished}
            >
              Upload Files
              <input
                type="file"
                name="avatar"
                hidden
                onChange={async (e) => {
                  trackPromise(
                    handleFileChange(e).then((file) => {
                      getValues("files").push(file)
                      setValue("files", getValues("files"))
                    }),
                    LoadingArea.UploadFile,
                  )
                }}
              />
            </Button>
            <Stack direction="row" spacing={0.75} flexWrap="wrap" rowGap={0.75}>
              {getValues("files")?.map((f) => (
                <Chip
                  label={f.filename}
                  variant="outlined"
                  // onDelete={()=> {}}
                />
              ))}
            </Stack>
          </LoadingSpinnerComponent>
        </Stack>
        <Stack>
          <Typography variant="body2">
            Cut and past your TEXT. Please past in TEXT of book, blogs, or other documents that
            provide clues to your strategies, tactics and methodologies.
          </Typography>
          <TextFieldControlled fullWidth name="text" label="" multiline required />
        </Stack>
        <Stack>
          <Typography variant="body2">
            Who is your ideal customer. We want to know the description of the person that buys your
            product. (we don't need to know what magazines she reads or how many kids he has). We
            want to who this person is so we can go find them.
          </Typography>
          <TextFieldControlled fullWidth name="idealCustomer" label="" multiline required />
        </Stack>
        <Stack>
          <Typography variant="body2">
            List the 10 pain points of ideal customer. What keeps them up at night as it relates to
            your solution. What is stopping them, making them inefficient, what are the things that
            don't work, etc.
          </Typography>
          <TextFieldControlled fullWidth name="customerPainPoints" label="" multiline required />
        </Stack>
        <Stack>
          <Typography variant="body2">
            Now describe your solution/solutions (your secret sauce) that solves the problems you
            listed above. What makes you better than your competition? Tell us about it here:
          </Typography>
          <TextFieldControlled fullWidth name="solutions" label="" multiline required />
        </Stack>
        <Stack>
          <Typography variant="body2">
            Now list 10 results that your client's get. What is the specific impact when they work
            with you? List them here:
          </Typography>
          <TextFieldControlled fullWidth name="results" label="" multiline required />
        </Stack>
        <SelectControlled
          size="small"
          name="totoArticleCategory"
          sx={{ width: 256 }}
          fullWidth
          items={categories?.map(({ name }) => ({ value: name, label: name }))}
          label="TOTO Post Article Category"
        />
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </FormProvider>
  )
}
